import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { WebOperationCode } from '../../role/role.model';
import { NgRxUtils } from '../../../shared/utility/NgRxUtils';
import { WidgetDTO, WidgetEntityType, WidgetFilterItem } from '../widget.model';
import { FilterRequest } from '../../filter-search/FilterSearch';

export const WidgetDefaultActions = createActionGroup({
  source: 'Widget',
  events: {
    'clear http status': emptyProps()
  }
});

export const WidgetApiActions = createActionGroup({
  source: 'Widget/API',
  events: {
    'get by module': props<{ module: WebOperationCode }>(),
    'get by module success': props<{ widgets: WidgetDTO[], module: WebOperationCode }>(),
    'get by module failure': NgRxUtils.httpStatusProps(),

    'get filter data source by widget': props<{ widget: WidgetDTO, filterRequest?: FilterRequest, searchValue?: string }>(),
    'get filter data source by widget success': props<{ dataSource: WidgetFilterItem[], entityType: WidgetEntityType }>(),
    'get filter data source by widget failure': NgRxUtils.httpStatusProps()
  }
});
